<template>
  <div class="container marketing-dashboard">
    <div class="row marketing-dash-row">
      <BoxModel 
            v-for="(data, i) in boxes"
            :key="i"
            :data="data"
            :start="dFilters.start_date"
            :end="dFilters.end_date"
        />
    </div>
    
    <div class="row marketing-dash-row" 
        v-for="(marketing, i) in MarketingDashboard"
        :key="i"
    >
        <template
            v-for="(stats, k) in marketing"
            :key="k"
        >
        <ChartModel
            v-if="Object.keys(graphs).length > 0 && graphs[stats.key] && stats.isChart"
            :chartOptions="graphs[stats.key].options"
            :series="graphs[stats.key].series"
            :title="stats.title"
            :backgroundColor="stats.backgroundColor"
            :col="stats.col"
            :commonClass="stats.class"
        />
        </template>
    </div>
    <CustomerImportModal />
  </div>

  
</template>
<script setup>
import { defineProps, computed } from "vue";
import { MarketingDashboard } from '@/core/utils/DashboardTables'
import ChartModel from "@/views/NEW/Dashboard/Models/ChartModel.vue";
import BoxModel from "@/views/NEW/Dashboard/Models/BoxModel.vue";
import { useStore } from "vuex";
import CustomerImportModal from "@/components/modals/forms/CustomerImportModal.vue";
import { dFilters } from "@/store/composable/Dashboard";

const props = defineProps({
  datas: {
    type: Function,
    required: true,
  },
});

const store = useStore();

const boxes = computed(() => {
  return store.getters.getCardBoxMarketAdminDashboard(props.datas)
})

const graphs = computed(() => {
    if(props.datas)
        return store.getters.getGraphStatisticsDashboard(props.datas)

    return {}
})

</script>
