<template>
    <div 
        class="tab-pane fade"
        id="kt_forms_Overview_1_tab_content_2"
    >
      <div class="container ps-2 pe-2">
        <div class="row">
            <TableModel 
                v-for="(header, i) in SalesPerson"
                :key="i"
                :data="store.getters.getSalesPersonInfoDashboard(salesPerson)[header.key]"
                :headers="header.headers"
                :title="header.title"
                :background-color="header.backgroundColor"
                :common-class="header.class"
                :col="header.col"
                :start="dFilters.start_date"
                :end="dFilters.end_date"
            />
        </div>
      </div>
    </div>
</template>

<script setup>
import { defineProps } from "vue";
import TableModel from "@/views/NEW/Dashboard/Models/TableModel.vue";
import { dFilters } from "@/store/composable/Dashboard";
import { SalesPerson } from "@/core/utils/DashboardTables";
import { useStore } from "vuex";

defineProps({
  salesPerson: {
    type: Function,
    required: true,
  },
});

const store = useStore();
</script>
