<template>
    <div :class="`col-sm-${col} ${commonClass} p-1`">
        <div class="card">
            <div class="card-header boder-0 pt-5" :style="`background-color: ${backgroundColor} !important;`">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1" :style="`color: ${backgroundFontColor} !important;`">
                        <span v-html="title" />
                            <span v-if="showCount" class="badge badge-primary bold uppercase mx-2">Total: {{ counts }}</span>
                    </span>
                </h3>
            </div>
            <div class="card-body card-custom-spacing-tblf-10 bg-white">
                <div v-if="showTopText">
                    <p>{{ topValues.title }}</p>
                    <h1 :style="`color: red; font-size: 1.75rem`">
                    {{ topValues.value }}
                    </h1>
                </div>
                <div class="pt-0 p-0">
                    <template v-if="showDiv">
                        <div class="row d-flex justify-content-center">
                            <div v-for="(item, i) in data" :key="i" :class="`col-sm-${getCol(data)}`">
                                <p :style="`text-align: center; font-size: 12px`">
                                {{ i }}
                                </p>
                                <h3
                                :style="`text-align: center; font-size: 20px; color: green`"
                                v-if="i"
                                >
                                {{ item }}
                                </h3>
                            </div>
                            <div class="col-sm-6" v-if="otherData && otherData.percentage">
                                <h3
                                    style="text-align: center; font-size: 2.5rem; color: red" 
                                    v-html="otherData.percentage" 
                                />
                            </div>
                        </div>
                        
                    </template>
                    <div class="table-responsive table-simple-dash" v-else>
                        <vc-tables 
                            :data="data" 
                            :headers="headers" 
                            :show-actions="false"
                        />
                        <el-pagination
                            v-if="isPagination"
                            v-show="otherData.last_page > 1"
                            v-model:currentPage="currentPage"
                            :page-size="parseInt(otherData.per_page)"
                            layout="prev, pager, next"
                            :total="otherData.total"
                            :style="`text-align: center`"
                            @current-change="handlePagination"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from 'vue'

const props = defineProps({
    headers: {
        type: Function,
        required: true,
        default: () => []
    },
    data: {
        type: Function,
        required: true,
        default: () => []
    },
    
    title: {
        type: String,
        required: true,
        default: ''
    },
    backgroundColor: {
        type: String,
        required: false,
        default: '#36a3f7'
    },
    backgroundFontColor: {
        type: String,
        required: false,
        default: '#ffffff'
    },
    col: {
        type: Number,
        required: true,
        default: 12
    },
    commonClass: {
        type: String,
        required: false,
        default: ''
    },
    showCount: {
        type: Boolean,
        required: false,
        default: false
    },
    counts: {
        type: Number,
        required: false,
        default: 0
    },
    showDiv: {
        type: Boolean,
        required: false,
        default: false
    },
    isPagination: {
        type: Boolean,
        required: false,
        default: false
    },
    otherData: {
        type: Object,
        required: false,
    },
    linkType: {
        type: String,
        required: false,
        default: ''
    },
    start: {
        type: String,
        required: false,
        default: ''
    },
    end: {
        type: String,
        required: false,
        default: ''
    },
    showTopText: {
        type: Boolean,
        required: false,
        default: false
    },
    topValues: {
        type: Function,
        required: false,
    },
})

const currentPage = ref(1)

function getCol(data){
    switch(Object.keys(data).length) {
        case 2: return 6
        case 4: return 3
        case 3: return 4
        default : return 6
    }
}

const emit = defineEmits([ 'pagination' ])

function handlePagination() {
    emit('pagination', currentPage.value, props.linkType, props.start, props.end)
}
</script>
