export const SalesPerson = [
    {
        title: 'Top Sales Person',
        col: 12,
        backgroundColor: '#36a3f7',
        key: 'top_sales_person',
        class: '',
        headers: [
            {
                label: 'Name',
                value: 'agent_name',
                isFixed: true,
                width: 200
            },
            {
                label: 'New Leads',
                value: 'new_leads',
                width: 120
            },
            {
                label: 'New Deals',
                value: 'new_deals',
                width: 120
            },
            {
                label: 'CR (New)',
                value: 'closing_ratio_new',
                width: 120
            },
            {
                label: 'WIP Deals</br>(New | Rwl | L Rwl | Oth)',
                value: 'wip_deals',
                width: 200
            },
            {
                label: 'Renewal Leads',
                value: 'renewal_leads',
                width: 120
            },
            {
                label: 'Renewal Deals',
                value: 'renewal_deals',
                width: 120
            },
            {
                label: 'CR (Renewal)',
                value: 'closing_ratio_renewal',
                width: 120
            },
            {
                label: 'LLR Leads',
                value: 'llr_leads',
                width: 120
            },
            {
                label: 'LLR Deals',
                value: 'llr_deals',
                width: 120
            },
            {
                label: 'CR (LLR)',
                value: 'closing_ratio_llr',
                width: 120
            },
            {
                label: 'Total Leads',
                value: 'total_leads',
                width: 120
            },
            {
                label: 'Total Deals',
                value: 'total_deals',
                width: 120
            },
            {
                label: 'CR (Total)',
                value: 'closing_ratio_total',
                width: 120
            },
            {
                label: 'Total Revenue',
                value: 'total_revenue',
                isPopover: true,
                headers: ['Revenue Target (CURRENCY)'],
                values: ['revenue_target_no_of_days'],
                width: 120
                
            },
            {
                label: 'Target % Met',
                value: 'target_percentage_met',
                width: 120
            },
            {
                label: 'Lost Leads',
                value: 'lost_leads',
                width: 120
            },
            {
                label: 'LR Lost',
                value: 'lrl_leads',
                width: 120
            },
            {
                label: 'CR (LL/LRL)',
                value: 'closing_ratio_lost_leads_and_lead_renewal_lost',
                width: 120
            },
            {
                label: 'Untouched Leads',
                value: 'untouched_leads',
                width: 120
            },
            {
                label: 'Untouched Customers',
                value: 'untouched_customers',
                width: 120
            },
            {
                label: 'Over Due Tasks',
                value: 'over_due_tasks',
                width: 120
            },
            {
                label: 'Pending Leads',
                value: 'pending_leads',
                width: 120
            },
            {
                label: 'Deals Cancelled',
                value: 'total_deals_cancelled',
                width: 120
            },
            {
                label: 'Cancellation Amount',
                value: 'deals_cancellation_payable_amount',
                width: 150
            },
        ]
    },
    {
        title: 'Sales Target Summary',
        col: 6,
        backgroundColor: '#36a3f7',
        key: 'sales_target_summary',
        class: 'mt-6',
        headers: [
            {
                label: 'Name',
                value: 'name',
                isFixed: true,
                width: 200
            },
            {
                label: 'Total Deals',
                value: 'total_deals',
                width: 100
            },
            {
                label: 'Sales Target',
                value: 'sales_target',
                width: 100
            },
            {
                label: 'Refund Amount',
                value: 'refund',
                width: 100
            },
            {
                label: 'Revenue Made',
                value: 'revenue_made',
                width: 100
            },
            {
                label: 'Revenue Target',
                value: 'revenue_target',
                width: 100
            },
        ]
    },
    {
        title: 'Leads Target Summary',
        col: 6,
        backgroundColor: '#36a3f7',
        key: 'leads_target_summary',
        class: 'mt-6',
        headers: [
            {
                label: 'Marketing Agent',
                value: 'name',
                isFixed: true,
                width: 200
            },
            {
                label: 'Number Of Leads',
                value: 'no_of_leads',
                width: 150
            },
            {
                label: 'Achieved',
                value: 'achieved',
                width: 100
            },
            {
                label: 'Marketing Spend',
                value: 'marketing_spend',
                width: 180
            },
        ]
    },
]

export const Statistics = [
    {
        title: 'Paid vs Organic New Leads',
        col: 4,
        backgroundColor: '#fff',
        key: 'paid_vs_organic_leads_new',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'Paid New Leads By UTM Source',
        col: 4,
        backgroundColor: '#fff',
        key: 'paid_utm_source_leads_new',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'Organic New Leads By UTM Source',
        col: 4,
        backgroundColor: '#fff',
        key: 'organic_utm_source_leads_new',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'Paid New Leads By UTM Campaign',
        col: 6,
        backgroundColor: '#fff',
        key: 'paid_utm_campaign_leads_new',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'Paid vs Organic WIP Deals',
        col: 6,
        backgroundColor: '#fff',
        key: 'paid_vs_organic_deals_wip',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'Paid vs Organic New Deals',
        col: 6,
        backgroundColor: '#fff',
        key: 'paid_vs_organic_deals_new',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'Paid New Deals By UTM Campaign',
        col: 6,
        backgroundColor: '#fff',
        key: 'paid_utm_campaign_deals_new',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'Paid New Deals By UTM Source',
        col: 6,
        backgroundColor: '#fff',
        key: 'paid_utm_source_deals_new',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'Organic New Deals By UTM Source',
        col: 6,
        backgroundColor: '#fff',
        key: 'organic_utm_source_deals_new',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'New Leads By Lead Source',
        col: 6,
        backgroundColor: '#fff',
        key: 'lead_source_leads_new',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'New Deals By Lead Source',
        col: 6,
        backgroundColor: '#fff',
        key: 'lead_source_deals_new',
        class: 'mb-6',
        isChart: true,
    },
    {
        title: 'Top Providers',
        col: 6,
        backgroundColor: '#36a3f7',
        key: 'top_providers',
        class: 'mb-6',
        isChart: false,
        showCount: true,
        headers: [
            {
                label: 'Provider Name',
                value: 'providers',
                align: 'center'
            },
            {
                label: 'Total Policies Sold',
                value: 'sold',
                align: 'center'
            },
            {
                label: 'Total Revenue (CURRENCY)',
                value: 'revenue',
                align: 'center'
            },
        ]
    },
    {
        title: 'Top Nationality',
        col: 6,
        backgroundColor: '#f4516c',
        key: 'top_nationality',
        class: 'mb-6',
        isChart: false,
        showCount: true,
        headers: [
            {
                label: 'Country',
                value: 'countries',
                align: 'center'
            },
            {
                label: 'Sold Policies',
                value: 'sold',
                align: 'center'
            },
        ]
    },
    {
        title: 'Top 10 Brand Policies Sold',
        col: 6,
        backgroundColor: '#f4516c',
        key: 'top_brand_policy_sold',
        class: 'mb-6',
        isChart: false,
        showCount: false,
        headers: [
            {
                label: 'Brand',
                value: 'brands',
                align: 'center'
            },
            {
                label: 'Sold Policies',
                value: 'sold',
                align: 'center'
            },
        ]
    },
    {
        title: 'Top 10 Vehicle Policies Sold',
        col: 6,
        backgroundColor: '#716aca',
        key: 'top_model_policy_sold',
        class: 'mb-6',
        isChart: false,
        showCount: false,
        headers: [
            {
                label: 'Manufacturer',
                value: 'brands',
                align: 'center'
            },
            {
                label: 'Model',
                value: 'model',
                align: 'center'
            },
            {
                label: 'Sold Policies',
                value: 'sold',
                align: 'center'
            },
        ]
    },
    {
        title: 'Discount Code Summary',
        col: 6,
        backgroundColor: '#34bfa3',
        key: 'discount_code',
        class: 'mb-6',
        isChart: false,
        showCount: false,
        headers: [
            {
                label: 'Code',
                value: 'promotion_code',
                align: 'center'
            },
            {
                label: 'Total Policies Sold',
                value: 'policies_sold',
                align: 'center'
            },
            {
                label: 'Status',
                value: 'status',
                align: 'center'
            },
        ]
    },
    {
        title: 'Policies sold w/o coupons',
        col: 6,
        backgroundColor: '#34bfa3',
        key: 'policies_sold_coupons',
        class: 'mb-6',
        isChart: false,
        showCount: false,
        showDiv: true,
    },
]

export const OtherInfos = [
    {
        title: 'Users Logs',
        col: 12,
        backgroundColor: '#34bfa3',
        key: 'user_logs',
        class: 'mb-6',
        linkType: 'user-logs',
        isPagination: true,
        showDiv: false,
        showTopText: false,
        headers: [
            {
                label: 'Name',
                value: 'name',
                align: 'center'
            },
            {
                label: 'Login Date & Time',
                value: 'date_time',
                align: 'center'
            },
            {
                label: 'IP Address',
                value: 'ip_address',
                align: 'center'
            },
            {
                label: 'Browser',
                value: 'browser',
                align: 'center'
            },
            {
                label: 'Lat',
                value: 'lat',
                align: 'center'
            },
            {
                label: 'Long',
                value: 'long',
                align: 'center'
            },
            {
                label: 'Status',
                value: 'status',
                align: 'center'
            },
        ]
    },
    {
        title: 'New Leads',
        col: 12,
        backgroundColor: '#34bfa3',
        key: 'new_leads',
        class: 'mb-6',
        linkType: 'new-leads',
        isPagination: true,
        showDiv: false,
        showTopText: true,
        headers: [
            {
                label: 'Name',
                value: 'name',
                align: 'center'
            },
            {
                label: 'Email',
                value: 'email',
                align: 'center'
            },
            {
                label: 'Mobile',
                value: 'mobile',
                align: 'center'
            },
            {
                label: 'Joined On',
                value: 'joined',
                align: 'center'
            },
        ]
    },
    {
        title: 'Pending Leads',
        col: 12,
        backgroundColor: '#34bfa3',
        key: 'pending_leads',
        class: 'mb-6',
        linkType: 'pending-leads',
        isPagination: true,
        showDiv: false,
        showTopText: true,
        headers: [
            {
                label: 'Name',
                value: 'name',
                align: 'center'
            },
            {
                label: 'Email',
                value: 'email',
                align: 'center'
            },
            {
                label: 'Mobile',
                value: 'mobile',
                align: 'center'
            },
            {
                label: 'Joined On',
                value: 'joined',
                align: 'center'
            },
        ]
    },
    {
        title: 'COD Transactions',
        col: 4,
        backgroundColor: '#36a3f7',
        key: 'cod_transactions',
        class: 'mb-6',
        linkType: 'cod-transaction',
        isPagination: true,
        showDiv: false,
        showTopText: true,
        headers: [
            {
                label: 'Quote Ref No',
                value: 'quote_ref_no',
                align: 'center'
            },
            {
                label: 'Policy',
                value: 'policy',
                align: 'center'
            },
            {
                label: 'Policy Price',
                value: 'policy_price',
                align: 'center'
            },
        ]
    },
    {
        title: 'Online Transactions',
        col: 4,
        backgroundColor: '#34bfa3',
        key: 'online_transactions',
        class: 'mb-6',
        linkType: 'online-transaction',
        isPagination: true,
        showDiv: false,
        showTopText: true,
        headers: [
            {
                label: 'Quote Ref No',
                value: 'quote_ref_no',
                align: 'center'
            },
            {
                label: 'Policy',
                value: 'policy',
                align: 'center'
            },
            {
                label: 'Policy Price',
                value: 'policy_price',
                align: 'center'
            },
        ]
    },
    {
        title: 'Direct Transactions',
        col: 4,
        backgroundColor: '#34bfa3',
        key: 'direct_transactions',
        class: 'mb-6',
        linkType: 'direct-transaction',
        isPagination: true,
        showDiv: false,
        showTopText: true,
        headers: [
            {
                label: 'Quote Ref No',
                value: 'quote_ref_no',
                align: 'center'
            },
            {
                label: 'Policy',
                value: 'policy',
                align: 'center'
            },
            {
                label: 'Policy Price',
                value: 'policy_price',
                align: 'center'
            },
        ]
    },
    {
        title: 'Policies Comprehensive',
        col: 6,
        backgroundColor: '#34bfa3',
        key: 'policies_comprehensive',
        class: 'mb-6',
        isChart: false,
        showCount: false,
        showDiv: true,
        showTopText: false,
    },
    {
        title: 'Policies Third Party',
        col: 6,
        backgroundColor: '#34bfa3',
        key: 'policies_tpl',
        class: 'mb-6',
        isChart: false,
        showCount: false,
        showDiv: true,
        showTopText: false,
    },
    {
        title: 'Avg. Quoted Vehicle',
        col: 6,
        backgroundColor: '#716aca',
        key: 'average_quoted_vehicle',
        class: 'mb-6',
        isChart: false,
        showCount: false,
        showDiv: true,
        showTopText: false,
    },
    {
        title: 'Closing Ratio',
        col: 6,
        backgroundColor: '#36a3f7',
        key: 'closing_ratio',
        class: 'mb-6',
        isChart: false,
        showCount: false,
        showDiv: true,
        showTopText: false,
    },
    {
        title: 'Lost Leads',
        col: 12,
        backgroundColor: '#34bfa3',
        key: 'lost_leads',
        class: 'mb-6',
        linkType: 'lost-leads',
        isPagination: true,
        showDiv: false,
        showTopText: true,
        headers: [
            {
                label: 'Name',
                value: 'name',
                align: 'center'
            },
            {
                label: 'Email',
                value: 'email',
                align: 'center'
            },
            {
                label: 'Mobile',
                value: 'mobile',
                align: 'center'
            },
            {
                label: 'Joined On',
                value: 'joined',
                align: 'center'
            },
        ]
    },
    {
        title: 'SMS Stat',
        col: 12,
        backgroundColor: '#fff',
        backgroundFontColor: '#000',
        key: 'sms_stat',
        class: 'mb-6',
        isChart: false,
        showCount: false,
        showDiv: true,
        showTopText: false,
    },
]

export const TeamSummary = [
    {
        label: 'Name',
        value: 'agent_name',
        isFixed: true,
        width: 150
    },
    {
        label: 'Revenue',
        value: 'total_revenue',
        width: 120
    },
    {
        label: 'Total Deals',
        value: 'total_deals',
        width: 120
    },
    {
        label: 'Total Leads',
        value: 'total_leads',
        width: 120
    },
    {
        label: 'Sales Target',
        value: 'sales_target',
        width: 120
    },
    {
        label: 'Variance',
        value: 'variance',
        width: 120
    },
    {
        label: 'Closing Ratio',
        value: 'closing_ratio_total',
        width: 120
    },
    {
        label: 'New Leads',
        value: 'new_leads',
        width: 120
    },
    {
        label: 'Quick Leads',
        value: 'quick_leads',
        width: 120
    },
    {
        label: 'New Deals',
        value: 'new_deals',
        width: 120
    },
    {
        label: 'CR (New)',
        value: 'closing_ratio_new',
        width: 120
    },
    {
        label: 'Renewal Leads',
        value: 'renewal_leads',
        width: 120
    },
    {
        label: 'Renewal Deals',
        value: 'renewal_deals',
        width: 120
    },
    {
        label: 'CR (Renewal)',
        value: 'closing_ratio_renewal',
        width: 120
    },
    {
        label: 'LLR Leads',
        value: 'llr_leads',
        width: 120
    },
    {
        label: 'LLR Deals',
        value: 'llr_deals',
        width: 120
    },
    {
        label: 'CR (LLR)',
        value: 'closing_ratio_llr',
        width: 120
    },
    {
        label: 'Lost Leads',
        value: 'lost_leads',
        width: 120
    },
    {
        label: 'LR Lost',
        value: 'lrl_leads',
        width: 120
    },
    {
        label: 'CR (LL/LRL)',
        value: 'closing_ratio_lost_leads_and_lead_renewal_lost',
        width: 120
    },
    {
        label: 'Pending',
        value: 'pending_leads',
        width: 120
    },
    {
        label: 'Refunds',
        value: 'total_refund',
        width: 120
    },
    {
        label: 'Cancellation',
        value: 'cancellation_amount',
        width: 150
    },
    {
        label: 'Over Due Tasks',
        value: 'over_due_tasks',
        width: 120
    },
    {
        label: 'Tasks Today',
        value: 'todays_task_count',
        width: 120
    },
    {
        label: 'Open Tasks in this Month',
        value: 'current_month_tasks',
        width: 180
    },
    {
        label: 'Open Tasks in previous Months',
        value: 'previous_month_tasks',
        width: 180
    },
]

export const MarketingDashboard = {
    card_box_1: [
        {
            title: 'Paid vs Organic Leads',
            col: 4,
            backgroundColor: '#fff',
            key: 'paid_vs_organic_leads_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Paid vs Organic Deals',
            col: 4,
            backgroundColor: '#fff',
            key: 'paid_vs_organic_deals_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Paid vs Organic WIP Deals',
            col: 4,
            backgroundColor: '#fff',
            key: 'paid_vs_organic_deals_wip',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_2: [
        
        {
            title: 'Paid Leads By UTM Source',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_source_leads_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Paid Deals By UTM Source',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_source_deals_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_3: [
        
        {
            title: 'Organic Leads By UTM Source',
            col: 6,
            backgroundColor: '#fff',
            key: 'organic_utm_source_leads_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Organic Deals By UTM Source',
            col: 6,
            backgroundColor: '#fff',
            key: 'organic_utm_source_deals_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_4: [
        {
            title: 'Leads By Lead Source',
            col: 6,
            backgroundColor: '#fff',
            key: 'lead_source_leads_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Deals By Lead Source',
            col: 6,
            backgroundColor: '#fff',
            key: 'lead_source_deals_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_5: [
        {
            title: 'Paid Leads By UTM Campaign',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_campaign_leads_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Paid Deals By UTM Campaign',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_campaign_deals_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_6: [
        {
            title: 'Paid Leads By UTM Content',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_content_leads_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Paid Deals By UTM Content',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_content_deals_new',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_7: [
        {
            title: 'Paid WIP Deals By UTM Campaign',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_campaign_deals_wip',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Organic WIP Deals By UTM Campaign',
            col: 6,
            backgroundColor: '#fff',
            key: 'organic_utm_campaign_deals_wip',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_8: [
        {
            title: 'Paid WIP Deals By UTM Content',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_content_deals_wip',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Organic WIP Deals By UTM Content',
            col: 6,
            backgroundColor: '#fff',
            key: 'organic_utm_content_deals_wip',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_9: [
        {
            title: 'Paid Renewal Leads By UTM Campaign',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_campaign_leads_renewal',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Paid Renewal Deals By UTM Campaign',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_campaign_deals_renewal',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_10: [
        {
            title: 'Paid LLR Leads By UTM Campaign',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_campaign_leads_llr',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Paid LLR Deals By UTM Campaign',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_campaign_deals_llr',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_11: [
        {
            title: 'Paid Renewal Leads By UTM Content',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_content_leads_renewal',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Paid Renewal Deals By UTM Content',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_content_deals_renewal',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_12: [
        {
            title: 'Paid LLR Leads By UTM Content',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_content_leads_llr',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
        {
            title: 'Paid LLR Deals By UTM Content',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_content_deals_llr',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
    card_box_13: [
        {
            title: 'Deleted Paid Leads By UTM Campaign',
            col: 6,
            backgroundColor: '#fff',
            key: 'paid_utm_campaign_leads_deleted',
            class: 'marketing-dash-item marketing-chart',
            isChart: true,
        },
    ],
}