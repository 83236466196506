<template>
  <div class="container sales-dashboard sales-agent">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="row sales-dashboard-row">
              <template v-if="datas && datas.card_box_1">
                <SasSmsTlsModel 
                  v-for="(card_1, i) in datas.card_box_1"
                  :key="i"
                  :data="card_1"
                />
              </template>
            </div>
            <div class="row sales-dashboard-row">
              <div class="col-md-8"
                v-if="datas && datas.card_box_2"
              >
                <div 
                  class="row sales-dashboard-row"
                  v-for="(card_2, i) in datas.card_box_2"
                  :class="{'mt-0': i == 'first_row'}"
                  :key="i"
                >
                  <SasSmsTlsModel 
                    v-for="(model, k) in card_2"
                    :key="k"
                    :data="model"
                  />
                </div>
              </div>
              <div class="col-md-4 sales-dashboard-item bg-white pe-0 dashboard-pie-chart" v-if="datas && datas.card_box_3 && !loading">
                <div class="">
                  <div class="card text-left me-0 p-0 border-0">
                    <div v-if="datas.show_agent_dashboard && hasSeries > 0" class="card-body pie-chart-container">
                      <ChartModel 
                          :chartOptions="datas.card_box_3.options"
                          :series="datas.card_box_3.series"
                          background-color="#fff"
                      />
                    </div>
                    <div v-else class="text-center m-20">Sorry, Sales Agent Dashboard Not Available</div>
                    
                  </div>
                </div>
              </div>
              <div class="col-md-4" v-else>
                <div class="card h-100">
                  <div class="card-body" style="place-content: center; background-color: #fff;">
                    <div class="d-flex justify-content-center">Loading...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-5" v-if="SMTLDashboard">
          <div class="card-header boder-0 pt-5" :style="`background-color: rgb(255, 255, 255) !important;`">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1" :style="`color: rgb(0, 0, 0) !important;`">
                Team Summary
              </span>
            </h3>
          </div>

          <div class="card-body card-custom-spacing-tblf-10 bg-white">
            <div class="pt-0 p-0">
              <div class="table-responsive table-simple-dash" id="displayResponseData">
                <vc-tables
                  :headers="header"
                  :data="datas.team_summary"
                  :show-actions="false"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from "vue";
import SasSmsTlsModel from "@/views/NEW/Dashboard/Models/SasSmsTlsModel.vue";
import ChartModel from "@/views/NEW/Dashboard/Models/ChartModel.vue";
import { useRoleBasedConditions } from '@/store/composable/User'
import { TeamSummary } from '@/core/utils/DashboardTables'

const props = defineProps({
  datas: {
    type: Function,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
    default: true
  },
});

const { SMTLDashboard, NotTL } = useRoleBasedConditions()

const header = computed(() => {
  if(NotTL){
    return TeamSummary.filter(x => x.value != 'total_leads').filter(y => y.value != 'closing_ratio_lost_leads_and_lead_renewal_lost')
  }

  return TeamSummary
})

const hasSeries = computed(() => {
  if(props.datas)
  return props.datas.card_box_3.series.reduce((a,b) => a + b, 0)

  return 0
})

</script>
