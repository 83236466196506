<template>

<div class="col-xxl-12 dashboard-filter-row ">
    <div class="card h-xl-100 ">
        <div class="row mx-4">
            <div class="col-md-12">
                <ul class="leads-policy nav nav-tabs nav-line-tabs fs-5">
                    <li class="nav-item">
                        <a class="nav-link cursor-pointer active" data-bs-toggle="pill"
                            href="javascript:void(0);" @click="tabClick(1)">
                            Graph
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link cursor-pointer" data-bs-toggle="pill"
                            href="javascript:void(0);" @click="tabClick(2)">
                            Team Summary
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

  <div class="container sales-dashboard sales-agent">
    <div class="row">
      <div class="col-sm-12">
        <div class="card" v-if="activeTab == 1">
          <div class="card-body" style="background-color: #fff;">
            <div class="col-md-12" v-if="datas && !loading">
                <div class="card text-left me-0 p-0 border-0">
                <div v-if="datas.show_agent_dashboard && hasSeries > 0" class="card-body pie-chart-container">
                    <ChartModel 
                        :chartOptions="datas.card_box_3.options"
                        :series="datas.card_box_3.series"
                        background-color="#fff"
                        chart-class="d-flex align-items-center"
                    />
                </div>
                <div v-else class="card-body text-center m-20">Sorry, Operation Manager Dashboard Not Available</div>
                
                </div>
            </div>
            <div class="col-md-12" v-else>
              <div class="text-center m-20"></div>
            </div>
          </div>
        </div>

        <div class="card mt-5" v-if="activeTab == 2">
          <div class="card-header boder-0 pt-5" :style="`background-color: rgb(255, 255, 255) !important;`">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1" :style="`color: rgb(0, 0, 0) !important;`">
                Team Summary
              </span>
            </h3>
          </div>

          <div class="card-body card-custom-spacing-tblf-10 bg-white">
            <div class="pt-0 p-4">
              <div class="table-responsive table-simple-dash" id="displayResponseData">
                <vc-tables
                  :headers="TeamSummary"
                  :data="datas.team_summary"
                  :show-actions="false"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref, computed } from "vue";
import ChartModel from "@/views/NEW/Dashboard/Models/ChartModel.vue";
import { TeamSummary } from '@/core/utils/DashboardTables'

const props = defineProps({
  datas: {
    type: Function,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
    default: true
  },
});

const activeTab = ref(1)

function tabClick(current) {
    activeTab.value = current
}

const hasSeries = computed(() => {
  if(props.datas)
  return props.datas.card_box_3.series.reduce((a,b) => a + b, 0)

  return 0
})

</script>
